<template>
  <div :class="pageshow ? ['SiderBar', 'open']:['SiderBar']">
    <div class="menu" v-if="pageshow">
        <div class="user">
          <div class="invitation">
            <div>
              <img src="@/assets/img/invitations.png" class="invitation_img" />
              <span>{{ $t('lang.swap80') }}</span>
            </div>
            <div class="more" @click="toNav('Invitation')">
              <span>{{ $t('lang.swap131') }}</span>
            </div>
          </div>
          <span class="invite_detail">{{ $t('lang.swap271') }}:</span>
          <div class="address">
            <div class="invitation_address">
              <span v-if="parent_id == 0" @click="popupfunopen"> {{ $t('lang.swap272') }}</span>
              <span v-else
                ><span v-if="invite_code == ''"> ----</span><span v-else>{{ linkshow }}</span>
              </span>
            </div>
            <div class="copys" style="opacity: 0.4" v-if="parent_id == 0">{{ $t('lang.swap61') }}</div>
            <div class="copys" v-else @click="copy" :data-clipboard-text="`${invite_linkleft}/#/?invite_code=${invite_code}`">
              <span>{{ $t('lang.swap61') }}</span>
            </div>
          </div>
        </div>
        <van-collapse v-model="activeNames">
          <van-collapse-item :name="index + 1" v-for="(item, index) of footerlist" :key="index" title-class="menu_li" :is-link="false">
            <template #title>
              <div @click="toNav(item.router, 'title', item.subordinate.length)" style="width: 100%; display: flex; align-items: center; justify-content: space-between">
                <div style="display: flex; align-items: center">
                  <img :src="$route.name == item.router ?item.activeimage : item.image" class="slot_image" />
                  <span>{{ $t(item.name) }}</span>
                </div>

                <van-icon name="arrow" />
              </div>
              <!-- <li class="line" ></li> -->
            </template>
            <div v-for="(e, i) of item.subordinate" :key="i" class="menu_list" @click="bNav(e.router, e.i)">
              <span :style="{ color: $route.name == e.router ? '#98E86E' :''  }">{{ $t(e.name) }}</span>
            </div>
          </van-collapse-item>
        </van-collapse>
        <van-collapse v-model="activeNames">
          <van-collapse-item :name="index + 1" v-for="(item, index) of menuList" :key="index" title-class="menu_li" :is-link="false">
            <template #title>
              <div @click="toNav(item.router, 'title', item.subordinate.length)" style="width: 100%; display: flex; align-items: center; justify-content: space-between">
                <div style="display: flex; align-items: center">
                  <img :src="item.image" class="slot_image" />
                  <span>{{ $t(item.name) }}</span>
                </div>

                <van-icon name="arrow" />
              </div>
              <!-- <li class="line" ></li> -->
            </template>
            <div v-for="(e, i) of item.subordinate" :key="i" class="menu_list" @click="bNav(e.router, e.i)">
              <span>{{ $t(e.name) }}</span>
            </div>
          </van-collapse-item>
        </van-collapse>
      
      </div>
      <div v-else>
        <div class="" >
          <div v-for="item in footerlist" style="width: 34px; height: 34px; margin-bottom: 44px;" @click="toNav(item.router, 'title', item.subordinate.length)">
            <img :src="$route.name == item.router ? item.activeimage : item.image" alt="" style="width: 100%; height: 100%;">
          </div>
        </div>
        <div class="" >
          <div v-for="item in menuList" style="width: 34px; height: 34px; margin-bottom: 44px;" @click="toNav(item.router, 'title', item.subordinate.length)">
            <img :src="item.image" alt="" style="width: 100%; height: 100%;">
          </div>
        </div>
      </div>
      <van-popup class="popup" v-model="popupshow" round closeable>
      <div class="p_main">
        <p class="m_title">{{ $t('lang.d76') }}</p>
        <span>{{ $t('lang.d189') }}:</span>
        <van-field type="text" v-model="fromInput" input-align="left" placeholder="" class="van-field" />
        <div class="m_button" @click="postucbin">{{ $t('lang.d78') }}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { invite, coin, homedata, user } from '@/api/index';
export default {
  props: ['pageshow'],
  data () {
    return {
      invite_linkleft:'',
      parent_id: 0,
      invite_code: '',
      popupshow:false,
      fromInput:'',
      activeNames: ['0'],
      footerlist: [
        {
          image: require('@/assets/imgsnowball/shoyefalse.png'),
          activeimage: require('@/assets/imgsnowball/shoyetrue.png'),
          name: 'lang.h1',
          router: 'Index',
          subordinate:[],
          i: '',
        },
        {
          image: require('@/assets/imgsnowball/jiaoyifalse.png'),
          activeimage: require('@/assets/imgsnowball/jiaoyitrue.png'),
          name: 'lang.h3',
          router: '',
          subordinate: [
          { name: 'V1', router: 'Swap' },
          { name: 'V2', router: 'SwapV2' },
          ],
          i: '',
        },
        {
          image: require('@/assets/imgsnowball/speciefalse.png'),
          activeimage: require('@/assets/imgsnowball/specietrue.png'),
          name: 'lang.pro14',
          router: 'specie',
          subordinate:[],
          i: '',
        },
       
        {
          image: require('@/assets/imgsnowball/fashetaifalse.png'),
          activeimage: require('@/assets/imgsnowball/fashetaitrue.png'),
          name: 'lang.h2',
          router: 'earn',
          subordinate:[],
          i: '',
        },
      
      ],
      menuList: [
      {
          image: require('@/assets/img/konto_icon.png'),
          name: 'lang.h59',
          subordinate: [],
          router: 'kuangchiIndex',
        },
        {
          image: require('@/assets/img/nft_icon.png'),
          name: 'lang.h60',
          subordinate: [],
          router: 'NFTCenter',
        },
       
        //下拉菜单
       
       

        {
          image: require('@/assets/img/game_icon.png'),
          name: 'Gamefi',
          subordinate: [],
          router: '',
        },
        {
          image: require('@/assets/img/shentai_icon.png'),
          name: 'lang.h129',
          subordinate: [],
          router: 'Index',
        },
        {
          image: require('@/assets/img/fankui_icon.png'),
          name: 'lang.h58',
          router: 'fankui',
          subordinate: [],
        },
      ],
    }
  },
  watch: {
    account() {
      this.init();
      // this.finished = false;
    },
    lang() {
      this.init();
    },
  },
  computed:{
    ...mapState(['account', 'lang', 'ColorCheckData']),
    linkshow() {
      var link = `${this.invite_linkleft}/#/?invite_code=${this.invite_code}`;
      const head = link.slice(0, 22);
      const tail = link.slice(link.length - 5);
      return head + '****' + tail;
    },
  },
  components: {

  },
  created(){
    this.init()
  },
  mounted() {

  },
  methods: {
    async init() {
      homedata.gettokenimgurl().then(res => {
        this.invite_linkleft = res.data.domain;
      });

      if (this.account) {
         this.getucinfo();
        
        
      }
    },
    toNav(router, title, length) {
      if (title) {
        if (router != '') {
          this.$router.push({ name: router });
        } else {
          if (length == 0) {
            // this.$toast(t his.$t('lang.swap65'));
          }
        }
      } else {
        if (router != '') {
          this.$router.push({ name: router });
        } else {
          // this.$toast(this.$t('lang.swap65'));
        }
      }
    },
    bNav(router, i) {
      if (router != '') {
        if (router == 'chat') {
        } else {
          this.$router.push({ name: router});
        }
      } else {
        // this.$toast(this.$t('lang.swap65'));
      }
    },
    popupfunopen() {
      this.popupshow = true;
    },
    getucinfo() {
      user.getucinfo(this.account).then(res => {
        console.log('w', res);
        this.invite_code = res.data.invite_code;
        this.parent_id = res.data.parent_id;
      });
    },
    postucbin() {
      this.popupshow = false;
      let data = {
        invite_code: this.fromInput,
      };
      user.postucbind(data, this.account).then(res => {
        console.log(res);
        this.init();
      });
    },
  },
}

</script>
<style scoped lang='less'>
.open {
  padding: 24px 20px;
  width: 298px !important;
}
@media screen and (max-width: 700px) {
  .SiderBar {
    
    display: none;
  }
}
.popup {
  
  .p_main {
    width: 288px;
    height: 248px;
    border-radius: 10px;
    padding: 10px;
    background: var(--text-color);
    .m_button {
      width: 228px;
      height: 48px;
      border-radius: 4px;
      margin: 0 auto;
      margin-top: 20px;
      color: #000;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
      text-align: center;
      line-height: 48px;
      background: rgba(152, 232, 110, 1);
    }
    .m_title {
      color: #2f272aff;
      font-size: 20px;
      font-weight: 700;
      font-family: 'PingFang';
      text-align: center;
    }
    span {
      color: #000000ff;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
      float: left;
      margin-bottom: 20px;
    }
  }
}
.SiderBar{
  z-index: 999;
  height: calc(100% - 50px);
  width: 60px;
  padding: 10px;
  position: fixed;
  left: 0;
  top: 50px;
  background: rgba(39, 44, 59, 1);
  transition: .5s;
}
.menu {
    .menu_li {
      font-weight: bold !important;
      font-family: 'HarmonyOSSansSC';

      div {
        background: rgba(39, 44, 59, 1);

        font-size: 16px;
        color: var(--text-color);
        font-weight: 600 !important;
        font-family: 'HarmonyOSSansSC';
      }
    }

    .slot_image {
      width: 26px;
      height: 26px;

      margin-right: 12px;
    }
    .menu_list {
      margin: 0 !important;
      
      background: rgba(39, 44, 59, 1) !important;
      border: none !important ;
      outline: none !important;
      font-family: 'HarmonyOSSansSC';
      color: var(--text-color);
      font-size: 14px;
      padding: 8px 0 8px 40px;
    }
  }
  .user {
      font-size: 18px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: var(--text-color);
      background: linear-gradient(136.87deg, rgba(23, 189, 186, 1) 0%, rgba(156, 238, 136, 1) 100%);
      // box-shadow: 0px 3px 6px 1px #ffd85e;
      // border: 1px solid #ffd361;
      border-radius: 10px;
      padding: 10px 14px;
      .invitation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;

        margin-bottom: 20px;
        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .invitation_img {
          display: block;
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
        .more {
          font-size: 12px;
          border-radius: 16px;
          // border: 1px solid var( --text-color);
          padding: 2px 12px;
          color: var(--text-color);
        }
      }
      .invite_detail {
        width: 52px;
        height: 15px;
        font-size: 12px;
        color: var(--text-color);
      }
      .address {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: var(--text-color);
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        margin-top: 5px;
        .invitation_address {
          width: 70%;
          text-align: center;
          background: #ffffff;
          color: #000;
          border-radius: 6px;
          padding: 8px 16px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
        .copys {
          color: var(--text-color);
          border-radius: 6px;
          padding: 6px 10px;
          margin-left: 10px;
          border: 1px solid var(--text-color);
          flex: 1;
          text-align: center;

          // width: 58px;
          // height: 27px;
          .span {
            // width: 24px;
            height: 17px;
            font-size: 12px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #6b5200;
          }
        }
      }
    }
    .van-field {
  width: 228px;
  margin: 0 auto;

  height: 48px;
  border-radius: 4px;
  font-weight: bold;
  background: #e8e8e8ff !important;
  padding: 14px !important;

  /deep/ .van-field__control {
    font-size: 14px;
    color: #000;
  }
}
</style>