<template>
  <div class="iso">
    <div class="i_main" :style="`background: url(${ColorCheckData == 'black' ? bglist.image : bglist.image1}) no-repeat;`">
      <div class="m_title">{{ $t('lang.h47') }}</div>
      <div class="m_text">{{ $t('lang.h48') }}</div>
      <div class="buttons">
        <div class="b_b1">{{ $t('lang.pro6') }}</div>
        <div class="b_b1">{{ $t('lang.pro7') }}</div>
      </div>
    </div>
    <div class="i_sosuo">
      <div class="s_line1">
        <div class="region">
          <div v-for="( item, index) of switchlist" :key="index" @click="shiswitch(index)"
            :class="index == regionIndex ? 'special' : ''">
            <span>{{ $t(item) }}</span>
          </div>
        </div>
        <div class="l_zhi">
          <van-switch v-model="checked" size="18px" active-color="rgba(182, 133, 255, 1)" inactive-color="#fff" />
          <span>{{ $t('lang.swap360') }}</span>
        </div>

      </div>
      <div class="i_pai">
        <div class="p_li">
          <span>{{ $t('lang.swap361') }}</span>
          <div class="menu">
            {{ $t('lang.swap363') }}
            <van-icon name="play" class="m_icon" @click="remeshowfun" />
            <div class="over" v-show="remenshow"></div>
          </div>
        </div>
        <div class="p_li">
          <span>{{ $t('lang.swap362') }}</span>
          <van-field type="number" v-model="toInput" input-align="left" :placeholder="`${$t('lang.swap364')}`"
            class="van-field" />
        </div>
      </div>
    </div>
    <div class="i_lon">
      <!-- <div class="meiyou">
        <img src="@/assets/img/zanwu.png" alt="" v-if="ColorCheckData == 'black'">
                        <img src="@/assets/img/zanwu1.png" alt="" v-else>
            <span>{{ $t('lang.swap365') }}</span>
        </div> -->
      <template>
        <!-- RT-USDT -->
        <div class="lon_list" style="margin-bottom: 26px;">
          <div class="lon_title">
            <div class="title_img">
              <img src="@/assets/tokenlogo/RT.png" alt="">
              <img src="@/assets/img/logo/USDT.png" alt="" style="margin-left: -10px;">
              <span style="color: rgba(255, 255, 255, 1);margin-left: 11px;">RT-USDT </span>
            </div>
            <div class="lont_tips" @click="socialCodeShowFun">{{ $t('lang.pro19') }}</div>
          </div>
          <div class="lon_line" style="margin-bottom: 22px;">
            <div class="line_left">
              <span style="color: rgba(153, 153, 153, 1);">{{ $t('lang.pro8') }}</span>
              <span style="color: rgba(255, 255, 255, 1);">{{ significantDigits((RTData.profit * 1).toFixed(6))
                }}</span>
            </div>
            <div class="line_right_button" @click="RTharvest('0')">{{ $t('lang.pro9') }}</div>
          </div>
          <div class="lon_line2" style="margin-bottom: 18px;" v-if="RTData.allowanceToRouter">
            <div class="line2_title">{{ $t('lang.pro10') }}</div>
            <van-button type="primary" class="line2_button" @click="approveRT" loading-text="Waiting..."
              :loading="RTData.showApprove">
              <span>{{ $t('lang.swap14') }}</span>
            </van-button>

          </div>

          <div class="lon_line" style="margin-bottom: 22px;" v-else>
            <div class="line_left">
              <span style="color: rgba(153, 153, 153, 1);">{{ $t('lang.pro10') }}</span>
              <span style="color: rgba(255, 255, 255, 1);">{{ significantDigits((RTData.myNumber * 1).toFixed(6))
                }}</span>
            </div>
            <div class="button">
              <img class="button_img" src="@/assets/img/reduce.png" @click="selectRT(false)" />

              <img class="button_img" src="@/assets/img/plus.png" @click="selectRT(true)" />
            </div>
          </div>
          <div class="lon_line3"
            style="border: 1px solid rgba(85, 89, 102, 1); border-radius: 16px; padding: 15px 17px; margin-bottom: 16px;">
            <div class="line3_left" style="color: #fff;">{{ $t('lang.pro13') }}</div>
            <div class="line3_right"><span style="color: rgba(152, 232, 110, 1);">{{
              RTData.userTvl }}</span> $</div>
          </div>
          <div class="lon_line3">
            <div class="line3_left">{{ $t('lang.pro12') }}</div>
            <div class="line3_right"><span style="color: rgba(152, 232, 110, 1);">{{ ((RTData.todayReward * 1) /
              Math.pow(10, 18)).toFixed(6) }}</span> RT</div>
          </div>
          <div class="lon_line3">
            <div class="line3_left">{{ $t('lang.pro11') }}</div>
            <div class="line3_right"><span style="color: rgba(152, 232, 110, 1);">{{ ((RTData.allReward * 1) /
              Math.pow(10, 18)).toFixed(6) }}</span> RT</div>
          </div>

        </div>

      </template>

    </div>

    <van-popup v-model="MiningPopupShowThree" round>
      <MiningPopup @handleConfirm="MiningPopupfirmThree" @getMyAddress="getBalanceThree" :selectShow="selectShowThree"
        :max="max" currencyCharacter="RT-USDT"></MiningPopup>
    </van-popup>
    <van-popup v-model="socialCodeShow" round closeable>
      <div class="socialCode">
        <div class="box" v-if="activationcode != ''">
          <div class="box_title">{{ $t('lang.pro18') }}</div>
          <div class="box_code">{{ activationcode }}</div>
          <div class="box_button copys" :data-clipboard-text="activationcode" @click="copy">{{ $t('lang.pro17') }}</div>
        </div>
        <div class="box" v-else>
          <img src="@/assets/img/TVL._icon.png" class="box_icon" alt="">
          <div class="box_title" style="margin-top: 18px; margin-bottom: 30px;"> {{ activationmsg }}</div>
          <div class="box_button" @click="socialCodeShow = false">{{ $t('lang.pro16') }}</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Clipboard, { copy } from 'clipboard';
import MiningPopup from '@/views/index/MiningPopup.vue';
import { mapState } from 'vuex';
import { getErc20Contract } from '@/utils/contractHelp';
import { useErc20Contract } from '@/utils/useContract';
import { MaxUint256 } from '@ethersproject/constants';
import { significantDigits, parseAmount, formatAmount } from '@/utils/format.js';
import RTlp from '../../methods/RTlp.js';
import { web3_api,basic } from '@/api/index';
export default {
  name: 'iso',
  components: {

  },
  data() {
    return {
      activationmsg: '',
      activationcode: '',
      socialCodeShow: false,
      regionIndex: 0,
      toInput: '',
      checked: false,
      remenshow: false,
      bglist: {
        image: require('@/assets/img/earnmoneybg.png'),
        image1: require('@/assets/img/earnmoneybg1.png'),
      },
      switchlist: ["lang.swap358", "lang.swap359"],
      selectShow: false, //true:质押,false:赎回
      selectShowTwo: false, //true:质押,false:赎回
      selectShowThree: false,
      MiningPopupShow: false,
      MiningPopupShowTwo: false,
      MiningPopupShowThree: false,
      max: 0,

      RTData: {
        confirmBtnShow: false, //是否授权
        showApprove: false, //是否授权
        allowanceToRouter: false,
        myNumber: 0,
        profit: 0,
        balance: 0, //
        max: 0,
        allReward: 0,
        todayReward: 0,
        userTvl: 0,
        maxTVL: 0,
        OtherTVL: 0,
        power: 0,
        pledge_num: 0,
      },

    }
  },
  components: {
    MiningPopup
  },
  computed: {
    ...mapState(['account', 'lang', 'ColorCheckData']),
  },
  created() {
    this.init()
  },
  watch: {
    account() {
      this.init()
    }
  },
  methods: {
    significantDigits,
    copy() {
      var clipboard = new Clipboard('.copys');
      clipboard.on('success', e => {
        this.$toast(this.$t('lang.swap300'));
        clipboard.destroy(); // 释放内存
      });
      clipboard.on('error', e => {
        this.$toast(this.$t('lang.pro20'));
        clipboard.destroy(); // 释放内存
      });
    },
    socialCodeShowFun() {
      this.socialCodeShow = true
    },
    shiswitch(index) {
      this.regionIndex = index;
    },
    init() {
      if (this.account) {
        Promise.all([this.handleApproveBtnShow(),
        this.getBalanceOf(),
        this.getDeposit(),
        this.getReward(),
        this.getpoolReward(),
        this.imactivationcode()
        ])

        // this.getlockData();
      }
    },
    imactivationcode() {
      basic.imactivationcode(this.account).then(res => {
        if (res.code == 200) {
          this.activationcode = res.data
        } else {
          this.activationmsg = res.msg
        }
      })
    },

    async getLpPrice() {

      
      const tvlprice = await web3_api.getLP_TVL_InUSDT('0x6541dcc04855472b70247642d813f5396e97f276');

      this.RTData.userTvl =  significantDigits(this.RTData.myNumber * parseFloat(tvlprice))
    },


    getpoolReward() {
      RTlp.poolRewardPOR().then(res => {

        this.RTData.allReward = res[0];
        this.RTData.todayReward = res[1];
      });



    },
    async handleApproveBtnShow() {
      if (this.account) {
        const RTerc20Contract = getErc20Contract('0x6541dcc04855472b70247642d813f5396e97f276');
        const RTto = '0xa0B83D37b1fE894B03ce8C0DFDC9f73DC93a6585';
        const RTallowance = await RTerc20Contract.methods.allowance(this.account, RTto).call();
        if (RTallowance == 0) {
          this.RTData.allowanceToRouter = true;
        } else {
          this.RTData.allowanceToRouter = false;
        }


      }
    },

    getBalanceThree(res) {
      console.log(res, 'res');

      let amount = parseAmount(String(res), 18);
      this.MiningPopupShowThree = false;
      if (this.selectShowThree) {
        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap158')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };


        RTlp.depositPOR(this.account, 0, amount, transactionsDetail)
          .then(res => {
            this.getDeposit();
            this.getBalanceOf();

          })
          .catch(e => { });



      } else {
        console.log(1);

        const transactionsDetail = {
          title: '',
          message: `${this.$t('lang.swap159')} `,
          addedTime: new Date().getTime(),
          from: this.account,
        };

        RTlp.withdrawPOR(this.account, 0, amount, transactionsDetail)
          .then(res => {
            this.getDeposit();
            this.getBalanceOf();
          })
          .catch(e => { });

      }
    },
    getDeposit() {
      RTlp.userinfoPOR(this.account, '0').then(res => {
        this.RTData.myNumber = res.amount / Math.pow(10, 18);
        this.getLpPrice()
      });


    },
    getReward() {
      RTlp.pendingRewardPOR('0', this.account).then(res => {
        this.RTData.profit = res / Math.pow(10, 18);
      });


    },
    async getBalanceOf() {
      if (this.account) {
        const erc20ContractRT = getErc20Contract('0x6541dcc04855472b70247642d813f5396e97f276');
        const balanceRT = await erc20ContractRT.methods.balanceOf(this.account).call();
        this.RTData.balance = formatAmount(balanceRT, 18);



      }
    },


    remeshowfun() {
      this.remenshow = !this.remenshow
    },
    RTharvest(i) {
      if (this.RTData.profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap66')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };
      RTlp.harvestPOR(this.account, 0, transactionsDetail)
        .then(res => {
          this.getReward();
        })
        .catch(e => { });
    },

    selectRT(state) {
      this.selectShowThree = state;
      if (state) {
        this.max = this.RTData.balance;
      } else {
        this.max = this.RTData.myNumber;
      }
      this.MiningPopupfirmThree();
    },
    MiningPopupfirmThree() {
      this.MiningPopupShowThree = !this.MiningPopupShowThree;
    },

    async approveRT() {
      this.RTData.showApprove = true;
      const erc20Contract = useErc20Contract('0x6541dcc04855472b70247642d813f5396e97f276');
      const amo = MaxUint256.toString();
      const gas = await erc20Contract.methods.approve('0xa0B83D37b1fE894B03ce8C0DFDC9f73DC93a6585', amo).estimateGas({ from: this.account })
      erc20Contract.methods
        .approve('0xa0B83D37b1fE894B03ce8C0DFDC9f73DC93a6585', amo)
        .send({
          from: this.account,
          gas,
          gasPrice: parseInt(this.gasPrice)
        })
        .on('confirmation', res => {
          this.RTData.showApprove = false;
          this.RTData.allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.RTData.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },
  },
}
</script>

<style lang="less" scoped>
.iso {

  background: var(--bg-color);

}

.socialCode {
  width: 314px;
  min-height: 232px;
  opacity: 1;
  border-radius: 30px;
  background: rgba(255, 255, 255, 1);
  padding: 37px 13px 29px 13px;

  .box {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .box_icon {
      width: 70px;
      height: 87px;
    }

    .box_title {
      width: 100%;
      text-align: center;
      font-size: 20px;
      color: rgba(34, 34, 34, 1);
      font-weight: bold;
    }

    .box_button {
      width: 100%;
      height: 48px;
      opacity: 1;
      border-radius: 110px;
      background: rgba(152, 232, 110, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(0, 0, 0, 1);
      font-size: 16px;
      font-weight: bold;
    }

    .box_code {
      margin: 20px 0;
      font-size: 20px;
      color: rgba(0, 0, 0, 1);

      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      opacity: 1;
      border-radius: 110px;
      background: rgba(245, 245, 245, 1);

      border: 1px solid rgba(201, 201, 201, 1);

    }
  }
}

.i_main {
  text-align: center;
  position: relative;
  width: 100%;
  height: 500px;
  // background: url(~@/assets/img/earnmoneybg.png)no-repeat;
  background-size: contain !important;
  background-position: 0 130px !important;
  // background-size: 130% 243px !important;
  // background-position: -60px 150px !important;
  padding: 20px;

  .m_title {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 32.82px;
    color: var(--text-color);
    text-align: center;
    margin-top: 40px;
  }

  .m_text {
    margin-top: 7px;
    text-align: center;
    color: rgba(158, 156, 163, 1);
    font-size: 16px;
    font-weight: 500;
    font-family: "HarmonyOSSansSC";


  }

  .buttons {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 40px;

    .b_b1 {

      border-radius: 16px;

      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 18.75px;
      color: rgba(0, 0, 0, 1);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      width: 160px;
      height: 48px;
      opacity: 1;
      border-radius: 72px;
      background: rgba(152, 232, 110, 1);
      box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.12);
    }
  }
}

.i_sosuo {
  width: 100%;
  padding: 21px;

  .s_line1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .region {
      padding: 3px;
      width: 130px;
      height: 40px;
      border-radius: 19px;
      border: 1px solid rgba(180, 135, 255, 0.71);
      display: flex;
      line-height: 38px;
      font-size: 12px;
      font-weight: 700;
      font-family: "PingFang";
      color: rgba(158, 156, 163, 1);
      // margin: 20px 0;
      margin-bottom: 20px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        // width: 192rpx;
        padding: 16px 0;
      }

      .special {
        // width: 50%;
        color: #000;
        background: rgba(152, 232, 110, 1);
        // box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
        border-radius: 34px;
      }
    }

    .l_zhi {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      span {
        opacity: 0.6;
        color: var(--text-color);
        font-size: 14px;
        font-weight: 700;
        font-family: "PingFang";
        margin-left: 5px;
      }

      /deep/.van-switch__node {
        background: var(--text-color);
      }
    }

  }

  .i_pai {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .p_li {
      height: 100%;
      color: var(--text-color);

      span {
        opacity: 0.6;
        margin-bottom: 20px;

      }
    }

  }

}

.menu {
  position: relative;
  margin-top: 20px;
  width: 137px;
  height: 50px;
  border-radius: 61px;
  opacity: 1;
  background: var(--line-bg-color);
  padding-left: 14px;
  line-height: 50px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 700;
  font-family: "PingFang";

  .m_icon {
    transform: rotate(90deg);
    color: var(--text-color);
    position: absolute;
    right: 20px;
    top: 15px;
  }

  .over {
    width: 100%;
    height: 100px;
    position: absolute;
    background: var(--text-color);
    bottom: -110px;
    z-index: 9999;
    left: 0;
    border-radius: 20px;
  }
}

.van-field {
  width: 178px;
  height: 50px;
  border-radius: 61px;
  color: #bababaff;
  font-size: 16px;
  font-weight: 700;
  font-family: "PingFang";
  background: var(--line-bg-color);
  margin-top: 20px;
  padding: 14px 14px !important;

  /deep/ .van-field__control {
    color: #000;
  }
}

.i_lon {
  width: 100%;
  min-height: 361px;
  border-radius: 30px 30px 0 0;
  margin-top: 20px;
  background: var(--box-bg-color);
  position: relative;
  padding: 28px 20px;


  .lon_list {
    padding: 19px 0px;
    width: 100%;
    border-bottom: 8px solid rgba(16, 20, 32, 1);
    opacity: 1;



    .lon_title {
      width: 100%;
      margin-bottom: 22px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 0px;
      line-height: 18px;
      color: rgba(0, 0, 0, 1);

      .lont_tips {
        padding: 6px 12px;
        background: rgba(120, 34, 199, 1);
        color: rgba(255, 255, 255, 1);
        border-radius: 19px;
        font-size: 14px;
      }

      .title_img {
        margin-right: 5px;
        display: flex;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
      }
    }

    .lon_line {
      padding: 16px 17px;
      width: 100%;
      height: 86px;
      opacity: 1;
      border-radius: 16px;
      background: rgba(16, 20, 32, 1);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .line_left {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
      }

      .line_right_button {
        width: 71px;
        height: 32px;
        opacity: 1;
        border-radius: 19px;
        background: rgba(152, 232, 110, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 24px;
        color: #000;
      }
    }

    .lon_line2 {
      width: 100%;
      height: 109px;
      opacity: 1;
      border-radius: 16px;
      background: rgba(16, 20, 32, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 18px;

      .line2_title {
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(255, 255, 255, 1);
      }

      .line2_button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 129px;
        height: 41px;
        opacity: 1;
        border-radius: 8px;
        background: rgba(152, 232, 110, 1);
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 24px;
        color: #000 !important;
        border: none;
      }
    }

    .lon_line3 {
      margin-bottom: 7px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .line3_left {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
      }

      .line3_right {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
      }
    }
  }

  .meiyou {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 89px;
      height: 158px;

      margin-bottom: 18px;
    }

    span {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 19.6px;
      color: rgba(168, 168, 168, 1);
    }
  }
}

.button {
  display: flex;
  align-items: flex-end;

  .confirmBtn {
    height: auto;
    font-size: 16px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    border-radius: 16px;
    padding: 4px 20px;
  }

  .button_img {
    width: 26px;
    height: 26px;
    margin-left: 10px;
  }
}
</style>