<template>
  <div>
    <div>
      <div class="swap">
        <div class="headWrap">
          <div class="headWrap_left">
            <div>{{ $t('lang.d6') }}</div>
            <div class="point">{{ $t('lang.h134') }}</div>
          </div>
          <div class="headWrap_img">
            <div>
              <img src="@/assets/img/set.png" @click="handleSettingShow" alt class="iconSet" />
            </div>
            <div>
              <img src="@/assets/img/record.png" @click="handleRecordShow" alt class="iconRecord" />
            </div>
          </div>
         
        </div>

        <div class="container">
          <div class="box">
            <van-row type="flex" justify="space-between">
              <van-col style="color: var(--text-color); font-weight: bold"> {{$t('lang.h135')}} </van-col>
              <van-col class="balances">{{ fromCur.balance ? $t('lang.h136') : '' }} {{ significantDigits(fromCur.balance) }}</van-col>
            </van-row>
            <van-row type="flex" justify="space-between" class="btRow">
              <div class="fromCurBox" @click="handleGetCurrency('from')" v-if="fromCur.name">
                <van-image round class="tokenImg" :src="`${fromCur.logoURI}`" />
                <div class="fromCurBox_right">
                  <div class="fromCurBox_right_top">
                    <div>{{ fromCur.symbol }}</div>
                    <img src="../../assets/img/arrows.png" class="arrows_img" />
                  </div>
                  <div class="fromCurBox_right_bottom">{{ $t('lang.h137') }}</div>
                </div>
              </div>
              <div class="fromCurBox" @click="handleGetCurrency('from')" v-else>
                <span style="font-weight: bold; font-size: 14px">{{ $t('lang.swap5') }}</span>
                <img src="../../assets/img/arrows.png" style="width: 10px; height: 6px" class="arrows_img" />
              </div>
              <van-field type="number" v-model="fromInput" input-align="right" @input="hanldeInputFrom" placeholder="0.0" class="van-field" />
            </van-row>
          </div>
          <div class="percentage">
            <span class="percentage_li" style="background: rgba(0, 186, 173, 1)" @click="handleToMax(0.25)">25%</span>
            <span class="percentage_li" style="background: rgba(53, 110, 241, 1)" @click="handleToMax(0.5)">50%</span>
            <span class="percentage_li" style="background: rgba(137, 174, 254, 1)" @click="handleToMax(0.75)">75%</span>
            <span class="percentage_li" style="background: rgba(180, 135, 255, 0.68)" @click="handleToMax(1)">100%</span>
          </div>
          <img src="@/assets/img/exchange.png" @click="handleTurnAround" alt class="iconDown" />
          <div class="box">
            <van-row type="flex" justify="space-between">
              <van-col style="color: var(--text-color); font-weight: bold"> {{ $t('lang.h138') }} </van-col>
              <van-col class="balances">{{ toCur.balance ? $t('lang.h136') : '' }}{{ significantDigits(toCur.balance) }}</van-col>
            </van-row>
            <van-row type="flex" justify="space-between" class="btRow">
              <div class="fromCurBox" @click="handleGetCurrency('to')" v-if="toCur.name">
                <van-image round class="tokenImg" :src="`${toCur.logoURI}`" />
                <div class="fromCurBox_right">
                  <div class="fromCurBox_right_top">
                    <div>{{ toCur.symbol }}</div>
                    <img src="../../assets/img/arrows.png" class="arrows_img" />
                  </div>
                  <div class="fromCurBox_right_bottom">{{ $t('lang.h137') }}</div>
                </div>
              </div>
              <div class="fromCurBox" @click="handleGetCurrency('to')" v-else>
                <span style="font-weight: bold; font-size: 14px">{{ $t('lang.swap5') }}</span>
                <img src="../../assets/img/arrows.png" style="width: 10px; height: 6px" class="arrows_img" />
              </div>
              <van-field type="number" v-model="toInput" input-align="right" @input="hanldeInputTo" placeholder="0.0" class="van-field" />
            </van-row>
            <!-- <van-row type="flex" justify="space-between" class="btRow">
              <van-col :span="24" class="title">
                <van-field type="number" v-model="toInput" input-align="right" @input="hanldeInputTo" placeholder="0.0" class="van-field" />
              </van-col>
            </van-row> -->
          </div>

          <van-button type="primary" class="swapBtn" v-if="!account" @click="handleLogin">{{ $t('lang.swap9') }}</van-button>
          <van-button type="default" class="swapBtn" v-else-if="insufficientLiquidityShow" disabled>{{ $t('lang.swap12') }}</van-button>
          <van-button type="default" class="swapBtn" v-else-if="getNoInputBtnShow" disabled>{{ $t('lang.swap10') }}</van-button>
          <van-button type="default" class="swapBtn" v-else-if="getNoBalanceBtnShow" disabled>{{ $t('lang.swap11') }}</van-button>
          <van-button type="default" class="swapBtn" v-else-if="getNoSelectBtnShow" disabled>{{ $t('lang.swap13') }}</van-button>
          <van-button type="primary" class="swapBtn" :loading="approveLoading" loading-text="Approving..." v-else-if="allowanceToRouter" @click="handleApprove">{{ $t('lang.swap14') }}</van-button>
          <van-button type="primary" class="swapBtn" v-else-if="getWrap" @click="handleDeposit">{{ $t('lang.swap15') }}</van-button>
          <van-button type="primary" class="swapBtn" v-else-if="getWithdraw" @click="handleWithdraw">{{ $t('lang.swap16') }}</van-button>
          <van-button type="default" class="swapBtn" disabled v-else-if="portion >= 20 && !this.expert" @click="handleWithdraw">{{ $t('lang.swap187') }}</van-button>
          <van-button type="primary" class="swapBtn" :loading="swapLoading" loading-text="Waitting" v-else @click="flashRight">{{ $t('lang.swap17') }}</van-button>
        </div>
      </div>

      <div class="current_prompt">
        <div class="tips" v-if="priceShow">
          <div class="subtitle">
            <van-col class="text">{{ $t('兑换价格') }}</van-col>
          </div>
          <div style="color: var(--text-color)">
            <van-col  style="color: var( --text-color);">
              1{{ fromCur.symbol }} = {{ significantDigits(price) }} {{ toCur.symbol }}
            
            </van-col>
            <!-- <van-col v-else style="color: var( --text-color);">
              1 {{ fromCur.symbol }}= {{ significantDigits(price) }} {{ toCur.symbol }}
              <van-image class="opppsite" :src="require('@/assets/img/opposites.png')" />
            </van-col> -->
          </div>
        </div>
        <div class="tips">
          <div class="subtitle">
            <span>{{ $t('lang.h139') }}</span>
          </div>
          <div style="color: var(--text-color)"> 
            <span v-if="this.toInput">{{ significantDigits(accSub(this.toInput, accMul(this.toInput, slippage / 100))) }} {{ toCur.symbol }}</span>
          </div>
        </div>
        <div class="tips">
          <div class="subtitle">
            <van-col class="text">{{ $t('lang.swap8') }}</van-col>
          </div>
          <div style="color: var(--text-color)">
            <van-col style="color: var(--text-color)">{{ slippage }}%</van-col>
          </div>
        </div>
       

        <div class="tips">
          <div class="subtitle">
            <span>{{ $t('lang.swap91') }}</span>
          </div>
          <div>
            <span v-if="portion <= 0.01" style="color: rgba(48, 255, 252, 1)">{{ less }} 0.01%</span>
            <span v-if="portion > 0.01 && portion <= 1" style="color: #356ef1">{{ portion.toFixed(2) }}%</span>
            <span v-if="portion > 1 && portion <= 2" style="color: #000">{{ portion.toFixed(2) }}%</span>
            <span v-if="portion > 2 && portion <= 5" style="color: rgb(255, 178, 55)">{{ portion.toFixed(2) }}%</span>
            <span v-if="portion > 5" style="color: rgb(237, 75, 158)">{{ portion.toFixed(2) }}%</span>
          </div>
        </div>
        <div class="tips">
          <div class="subtitle">
            <span>{{ $t('lang.swap93') }}</span>
          </div>
          <div class="special" style="color: var(--text-color)">
            <span>{{ significantDigits(this.fromInput * 0.0025) }} {{ this.fromCur.symbol }}</span>
          </div>
        </div>
        <div class="tips">
          <div class="subtitle">
            <span>{{ $t('lang.h140') }}</span>
          </div>
          <div style="color: var(--text-color)">
            <van-col style="color: var(--text-color)">{{ pathRouter }}</van-col>
          </div>
        </div>
      </div>
    </div>

    <!-- <div v-show="chartShow">
  
      </div>-->
    <van-popup v-if="tokenListShow" v-model="tokenListShow" round position="bottom">
      <currency-list @listenClose="handleTokenListClose" @listenSelecteCurrency="handlerSelecteCurrency" />
    </van-popup>

    <van-popup v-model="settingShow" round position="bottom">
      <SettingsModal @listenClose="handleSettingClose" />
    </van-popup>

    <van-popup v-model="swapDetailShow" :round="true" @closed="handleSwapDetailClosed" position="bottom">
      <swap-detail @listenClose="handleSwapDetailClose" @listenConfirm="handleConfirm" :fromCur="fromCur" :toCur="toCur" :swapDetail="swapDetail" />
    </van-popup>

    <van-popup v-model="pendingDetailShow" round @closed="handlePendingSwapClosed" position="bottom">
      <pending-swap @listenClose="handlePendingSwapClose" :pendingDetail="pendingDetail" :fromCur="fromCur" :toCur="toCur" />
    </van-popup>

    <van-popup v-model="transactionRecordShow" round position="bottom">
      <transaction-record @listenClose="handleTransactionRecordClose" />
    </van-popup>

    <van-popup v-model="VisualChartShow" round position="bottom">
      <visual-chart @VisualChartClose="visualChartClose"></visual-chart>
    </van-popup>

    <van-popup v-model="swapPopupShow" round position="bottom">
      <SwapPopup @cancelButton="popupSwitch" @switch="swapSwitch" :swapPopupData="swapPopupData"></SwapPopup>
    </van-popup>
  </div>
</template>
  
  <script>
  import Web3 from 'web3';
import CurrencyList from '@/components/CurrencyList.vue';
import SwapDetail from '@/views/swap/SwapDetail.vue';
import PendingSwap from '@/views/swap/PendingSwap.vue';
import TransactionRecord from '@/views/swap/TransactionRecord.vue';
import VisualChart from '@/views/swap/VisualChart.vue';
import SwapPopup from '@/views/swap/SwapPopup.vue';
import SettingsModal from '@/components/SettingsModal.vue';

import { mapState } from 'vuex';
import { formatAmount, parseAmount, accMul, accDiv, toFixed, accAdd, accSub, accGt, significantDigits, gasProcessing } from '@/utils/format.js';
import { isBnb } from '@/utils/verify.js';
import { getJackRouterAddress } from '@/utils/addressHelp';
import { MaxUint256 } from '@ethersproject/constants';
import { getErc20Contract, getJackRouterContract, getJackPairContract, getJackFactoryContract } from '@/utils/contractHelp';
import { simpleRpcProvider } from '@/utils/provider';
import { useJackRouterContract,useJackFactoryContractV2, useErc20Contract, useWethContract } from '@/utils/useContract';
import { getAllPair } from '@/utils/pairHelp';
import web3 from 'web3';
import { homedata, invite } from '../../api/index';
import { BNB } from '@/config/constants/bnb';
import jackFactoryAbiV2 from '@/config/abi/FactoryV2.json';
export default {
  name: 'Home',
  data() {
    return {
      fromBalance: '',
      tokenListShow: false, //选择代币列表
      settingShow: false, //设置滑点
      isShow: true, //是否展示余额
      approveLoading: false, //授权loading
      swapLoading: false,
      pendingDetailShow: false, //等待交换弹窗
      transactionRecordShow: false, //交易记录
      pendingDetail: { status: 0, hash: '', fromInput: '', toInput: '' },
      allPaths: [], //所有的多路径
      currentPaths: [], //当前所有多路径
      pathIndex: -1, //当前选择的多路径
      pathRouter: '',
      insufficientLiquidityShow: false, //当固定输出时，大于池子深度
      fromInput: '', //from输入框
      fromCur: {},

      toInput: '',
      toCur: {
        balance: '',
        name: '',
        symbol: '',
        address: '',
        chainId: '',
        decimals: '',
        logoURI: '',
      },
      tokenSource: 'from',
      priceDirection: false,
      price: '',
      priceShow: false,
      inputSource: '',
      allowanceToRouter: false, //显示是否需要授权
      swapDetailShow: false, //确认交换弹窗
      swapDetail: { fromInput: '', toInput: '', amountIn: 0, amountInMax: 0, amountOut: 0, amountOutMin: 0, path: [], to: '', deadline: 0, inputSource: 'from', fee: 0 },
      chartShow: true,
      VisualChartShow: false, //数据图弹窗状态
      portion: undefined,
      switchVersionShow: '0',
      less: '<',
      swapPopupShow: false, //确认交换弹窗
      swapPopupData: {
        swapQuantity: '0',
        swapTitle: '警告',
        swapContent: '内容',
      },
      site: '',
      SecondaryAuthorization: false,
      imgurl: '',
    };
  },
  mounted() {
   
    console.log(window.ethereum);
  },
  beforeDestroy() {
    //清除定时器
    window.clearInterval(this.timer);
    window.clearInterval(this.timerBalance);
  },
  async created() {
    //获取余额
    this.fromCur = BNB;
    homedata.gettokenimgurl().then(res => {
      this.imgurl = res.data.image_domain;
    });
    this.initialization();
    this.site = this.$route.query.invite_address ? this.$route.query.invite_address : '';
  },
  watch: {
    account() {
      //获取账号
      this.initialization();
    },
  },
  components: {
    CurrencyList,
    SettingsModal,
    SwapDetail,
    PendingSwap,
    TransactionRecord,
    VisualChart,
    SwapPopup,
  },
  computed: {
    ...mapState(['account', 'provider', 'gasPrice', 'slippage', 'deadline', 'multipath', 'pool', 'expert', 'ColorCheckData1']),

    // 是否需要显示余额不足提示
    getNoBalanceBtnShow() {
      if (accGt(this.fromInput, this.fromCur.balance)) {
        return true;
      }
      return false;
    },
    //是否需要显示inputBtn按钮
    getNoInputBtnShow() {
      if ((parseFloat(this.fromInput) === 0 || this.fromInput === '') && this.fromCur.address !== '') {
        return true;
      } else if ((parseFloat(this.toInput) === 0 || this.toInput === '') && this.toCur.address !== '') {
        return true;
      }
      return false;
    },
    // 是否需要显示选择币种
    getNoSelectBtnShow() {
      if (this.fromCur.address == '' || this.toCur.address == '') {
        return true;
      }
      return false;
    },
    // 是否BNB - WBNB
    getWrap() {
      if (isBnb(this.fromCur) && this.toCur.address == this.fromCur.address) {
        return true;
      }
      return false;
    },
    // 是否WBNB - BNB
    getWithdraw() {
      if (isBnb(this.toCur) && this.toCur.address == this.fromCur.address) {
        return true;
      }
      return false;
    },
  },
  methods: {
    bind() {
      let data = { recommend_address: this.site };
      invite.userBind(data, this.account).then(res => {
        if (res.code === 200) {
        } else {
        }
      });
    },

    //判断是否登录
    handleLogin() {
      this.$store.dispatch('setWebProvider');
    },
    //是否授权
    async handleDeposit() {
      const wethContract = useWethContract();
      const payableAmount = parseAmount(this.fromInput);
      const gas = await wethContract.methods.deposit().estimateGas({ from: this.account, value: payableAmount });
      wethContract.methods
        .deposit()
        .send({ from: this.account, value: payableAmount, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
        .on('transactionHash', hash => {
          const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap15')} ${this.toInput} ${this.toCur.symbol}`;
          const title = this.$t('lang.swap18');
          const transactionsDetail = {
            hash,
            title,
            message,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
        })
        .on('receipt', receipt => {})
        .on('error', function (error, receipt) {
          throw error;
        });
    },
    //取出
    async handleWithdraw() {
      const wethContract = useWethContract();
      const wad = parseAmount(this.fromInput);
      const gas = await wethContract.methods.withdraw(wad).estimateGas({ from: this.account });
      wethContract.methods
        .withdraw(wad)
        .send({ from: this.account, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
        .on('transactionHash', hash => {
          const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap16')} ${this.toInput} ${this.toCur.symbol}`;
          const title = this.$t('lang.swap18');
          const transactionsDetail = {
            hash,
            title,
            message,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
        })
        .on('receipt', receipt => {})
        .on('error', function (error, receipt) {
          throw error;
        });
    },
    //关闭详情
    handleSwapDetailClose() {
      this.swapDetailShow = false;
    },
    //关闭详情
    handleSwapDetailClosed() {
      this.swapDetail = { fromInput: '', toInput: '', amountIn: 0, amountInMax: 0, amountOut: 0, amountOutMin: 0, path: [], to: '', deadline: 0, inputSource: 'from', fee: 0 };
    },
    handlePendingSwapClose() {
      this.pendingDetailShow = false;
    },
    handleTransactionRecordClose() {
      this.transactionRecordShow = false;
    },
    handlePendingSwapClosed() {
      this.pendingDetail = { status: 0, hash: '', fromInput: '', toInput: '' };
    },
    handleTokenListClose() {
      this.tokenListShow = false;
    },
    handleSettingClose() {
      this.settingShow = false;
    },

    // 获取两边币种余额
    async handleCurBalance() {
      if (this.account) {
        if (isBnb(this.fromCur)) {
          const balance = await simpleRpcProvider.eth.getBalance(this.account);
          this.fromCur.balance = formatAmount(balance);
        }

        if (!isBnb(this.fromCur) && this.fromCur.address != '') {
          const erc20Contract = getErc20Contract(this.fromCur.address);
          const balance = await erc20Contract.methods.balanceOf(this.account).call();
          this.fromCur.balance = formatAmount(balance, this.fromCur.decimals);
        }

        if (isBnb(this.toCur)) {
          const balance = await simpleRpcProvider.eth.getBalance(this.account);
          this.toCur.balance = formatAmount(balance);
        }

        if (!isBnb(this.toCur) && this.toCur.address != '') {
          const erc20Contract = getErc20Contract(this.toCur.address);
          const balance = await erc20Contract.methods.balanceOf(this.account).call();
          this.toCur.balance = formatAmount(balance, this.toCur.decimals);
        }
        this.$forceUpdate()
      }
    },
    flashRight() {
      if (this.portion > 10 && this.expert == false) {
        this.swapPopupShow = true;
      } else {
        this.handleSwap();
      }
    },
    // 闪兑
    handleSwap() {
      if (this.fromCur.address == '0x1676BDdf162A800cCb5f9e81b0F402A33c51e06c' || this.toCur.address == '0x1676BDdf162A800cCb5f9e81b0F402A33c51e06c') {
        // Toast.fail(this.$t('lang.swap65'));
        // this.$toast.fail(this.$t('lang.swap65'))
        return;
      }
      const slippage = accDiv(this.slippage, 100);
      this.swapDetail.inputInMax = significantDigits(accAdd(this.fromInput, accMul(this.fromInput, slippage)));
      this.swapDetail.inputOutMin = significantDigits(accSub(this.toInput, accMul(this.toInput, slippage)));
      this.swapDetail.inputSource = this.inputSource;
      this.swapDetail.price = this.price;
      this.swapDetail.fromInput = this.fromInput;
      this.swapDetail.toInput = this.toInput;
      this.swapDetail.fee = accMul(this.swapDetail.inputInMax, 0.003);
      this.swapDetailShow = true;
    },
    //同意
    handleConfirm() {
      console.log(222222);
      const slippage = accDiv(this.slippage, 100);
      const amountIn = parseAmount(this.fromInput, this.fromCur.decimals);
      const amountInMax = toFixed(accAdd(amountIn, accMul(amountIn, slippage)), 0);
      const amountOut = parseAmount(this.toInput, this.toCur.decimals);
      const amountOutMin = toFixed(accSub(amountOut, accMul(amountOut, slippage)), 0);
      let path = [];
      console.log(slippage);
      const currentPath = this.currentPaths[this.pathIndex];
      if (currentPath.length == 1) {
        path = [this.fromCur.address, this.toCur.address];
      }

      if (currentPath.length == 2) {
        path = [this.fromCur.address, currentPath[0].toCur.address, currentPath[1].toCur.address];
      }
      const to = this.account;
      const deadline = accAdd(accDiv(Date.parse(new Date()), 1000), accMul(this.deadline, 60));
      this.pendingDetail.fromInput = this.fromInput;
      this.pendingDetail.toInput = this.toInput;
      console.log('inputSource',this.inputSource);
      console.log(isBnb(this.fromCur));
      if (isBnb(this.fromCur) && this.inputSource == 'from') {
         console.log('isBnb(this.fromCur) && this.inputSource == from');
        this.swapExactETHForTokens(amountIn, amountOutMin, path, to, deadline);
        return;
      }

      if (isBnb(this.fromCur) && this.inputSource == 'to') {
        console.log('isBnb(this.fromCur) && this.inputSource == to');
        this.swapETHForExactTokens(amountOut, amountInMax, path, to, deadline);
        return;
      }

      if (isBnb(this.toCur) && this.inputSource == 'from') {
        console.log('isBnb(this.toCur) && this.inputSource == from');
        this.swapExactTokensForETH(amountIn, amountOutMin, path, to, deadline);
        return;
      }

      if (isBnb(this.toCur) && this.inputSource == 'to') {
        console.log('isBnb(this.toCur) && this.inputSource == to');
        this.swapTokensForExactETH(amountOut, amountInMax, path, to, deadline);
        return;
      }
      if (this.inputSource == 'from') {
        console.log('this.inputSource == from');
        this.swapExactTokensForTokens(amountIn, amountOutMin, path, to, deadline);

        return;
      }

      if (this.inputSource == 'to') {

        console.log('this.inputSource == to');
        this.swapTokensForExactTokens(amountOut, amountInMax, path, to, deadline);
        return;
      }
    },

    async swapExactETHForTokens(amountIn, amountOutMin, path, to, deadline) {
      const jackRouterContract = useJackRouterContract();
      const gas = await jackRouterContract.methods.swapExactETHForTokens(amountOutMin, path, to, deadline).estimateGas({ from: this.account, value: amountIn });
      this.swapDetailShow = false;
      this.swapLoading = true;
      this.pendingDetailShow = true;
      jackRouterContract.methods
        .swapExactETHForTokens(amountOutMin, path, to, deadline)
        .send({ from: this.account, value: amountIn, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
        .on('transactionHash', hash => {
          const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap1')} ${this.toInput} ${this.toCur.symbol}`;
          const title = this.$t('lang.swap18');
          const transactionsDetail = {
            hash,
            title,
            message,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
          this.pendingDetail.status = 1;
          this.pendingDetail.hash = hash;
        })
        .on('receipt', receipt => {
          this.swapLoading = false;
        })
        .on('error', (error, receipt) => {
          this.pendingDetail.status = 2;
          this.swapLoading = false;
          throw error;
        });
    },
    async swapETHForExactTokens(amountOut, amountInMax, path, to, deadline) {
      const jackRouterContract = useJackRouterContract();
      const gas = await jackRouterContract.methods
        .swapETHForExactTokens(amountOut, path, to, deadline)
        .estimateGas({ from: this.account, value: amountInMax })
        .catch(async e => {
          const gas = await jackRouterContract.methods.swapExactETHForTokensSupportingFeeOnTransferTokens(amountOut, path, to, deadline).estimateGas({ from: this.account, value: amountInMax });
          this.swapDetailShow = false;
          this.pendingDetailShow = true;
          jackRouterContract.methods
            .swapExactETHForTokensSupportingFeeOnTransferTokens(amountOut, path, to, deadline)
            .send({ from: this.account, value: amountInMax, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
            .on('transactionHash', hash => {
              const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap1')} ${this.toInput} ${this.toCur.symbol}`;
              const title = this.$t('lang.swap18');
              const transactionsDetail = {
                hash,
                title,
                message,
                addedTime: new Date().getTime(),
                from: this.account,
              };
              this.$store.commit('SETTRANSACTIONS', transactionsDetail);
              this.pendingDetail.status = 1;
              this.pendingDetail.hash = hash;
            })
            .on('error', (error, receipt) => {
              this.pendingDetail.status = 2;
              throw error;
            });
        });
      this.swapDetailShow = false;
      this.swapLoading = true;
      this.pendingDetailShow = true;
      jackRouterContract.methods
        .swapETHForExactTokens(amountOut, path, to, deadline)
        .send({ from: this.account, value: amountInMax, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
        .on('transactionHash', hash => {
          const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap1')} ${this.toInput} ${this.toCur.symbol}`;
          const title = this.$t('lang.swap18');
          const transactionsDetail = {
            hash,
            title,
            message,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
          this.pendingDetail.status = 1;
          this.pendingDetail.hash = hash;
        })
        .on('receipt', receipt => {
          this.swapLoading = false;
        })
        .on('error', (error, receipt) => {
          this.pendingDetail.status = 2;
          throw error;
        });
    },
    async swapExactTokensForETH(amountIn, amountOutMin, path, to, deadline) {
      const jackRouterContract = useJackRouterContract();
      jackRouterContract.methods
        .swapExactTokensForETH(amountIn, amountOutMin, path, to, deadline)
        .estimateGas({ from: this.account })
        .then(res => {
          this.swapDetailShow = false;
          this.pendingDetailShow = true;
          this.swapLoading = true;
          jackRouterContract.methods
            .swapExactTokensForETH(amountIn, amountOutMin, path, to, deadline)
            .send({ from: this.account, gas: gasProcessing(res), gasPrice: web3.utils.numberToHex(this.gasPrice) })
            .on('transactionHash', hash => {
              const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap1')} ${this.toInput} ${this.toCur.symbol}`;
              const title = this.$t('lang.swap18');
              const transactionsDetail = {
                hash,
                title,
                message,
                addedTime: new Date().getTime(),
                from: this.account,
              };
              this.$store.commit('SETTRANSACTIONS', transactionsDetail);
              this.pendingDetail.status = 1;
              this.pendingDetail.hash = hash;
            })
            .on('receipt', receipt => {
              this.swapLoading = false;
            })
            .on('error', (error, receipt) => {
              this.pendingDetail.status = 2;
              throw error;
            });
        })
        .catch(e => {
          this.$toast('Try increasing your slippage tolerance.');
          this.swapDetailShow = false;
        });
    },
    async swapTokensForExactETH(amountOut, amountInMax, path, to, deadline) {
      const jackRouterContract = useJackRouterContract();
      const gas = await jackRouterContract.methods.swapTokensForExactETH(amountOut, amountInMax, path, to, deadline).estimateGas({ from: this.account });
      this.swapDetailShow = false;
      this.pendingDetailShow = true;
      jackRouterContract.methods
        .swapTokensForExactETH(amountOut, amountInMax, path, to, deadline)
        .send({ from: this.account, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
        .on('transactionHash', hash => {
          const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap1')} ${this.toInput} ${this.toCur.symbol}`;
          const title = this.$t('lang.swap18');
          const transactionsDetail = {
            hash,
            title,
            message,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
          this.pendingDetail.status = 1;
          this.pendingDetail.hash = hash;
        })
        .on('error', (error, receipt) => {
          this.pendingDetail.status = 2;
          throw error;
        });
    },

    async swapExactTokensForTokens(amountIn, amountOutMin, path, to, deadline) {
      console.log('amountIn:', amountIn, 'amountOutMin:', amountOutMin, 'path:', path, 'to:', to, 'deadline:', deadline);
      const jackRouterContract = useJackRouterContract();
      const gas = await jackRouterContract.methods
        .swapExactTokensForTokens(amountIn, amountOutMin, path, to, deadline)
        .estimateGas({ from: this.account })
        .catch(async e => {
          // 获取gas报错走这里
          const gas = await jackRouterContract.methods
            .swapExactTokensForTokensSupportingFeeOnTransferTokens(amountIn, amountOutMin, path, to, deadline)
            .estimateGas({ from: this.account })
            .catch(res => {
              this.swapPopupData.swapQuantity = '1';
              this.swapPopupData.swapTitle = 'error';
              this.swapPopupData.swapContent = 'The transaction cannot succeed due to error: undefined. This is probably an issue with one of the tokens you are swapping.';
              this.swapDetailShow = false;
              this.pendingDetailShow = false;
              this.swapPopupShow = true;
            });
          if (gas) {
            this.swapDetailShow = false;
            this.pendingDetailShow = true;
            jackRouterContract.methods
              .swapExactTokensForTokensSupportingFeeOnTransferTokens(amountIn, amountOutMin, path, to, deadline)
              .send({ from: this.account, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
              .on('transactionHash', hash => {
                const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap1')} ${this.toInput} ${this.toCur.symbol}`;
                const title = this.$t('lang.swap18');
                const transactionsDetail = {
                  hash,
                  title,
                  message,
                  addedTime: new Date().getTime(),
                  from: this.account,
                };
                this.$store.commit('SETTRANSACTIONS', transactionsDetail);
                this.pendingDetail.status = 1;
                this.pendingDetail.hash = hash;
              })

              .on('error', (error, receipt) => {
                this.pendingDetail.status = 2;
                this.swapLoading = false;
                throw error;
              });
          }
        });
      console.log('gas', gas);
      // const gas = 512433
      //   .estimateGas({ from: this.account })
      // let Web3 = new web3(window.ethereum);
      // const gas =await Web3.eth.getGasPrice()

      if (gas) {
        this.swapDetailShow = false;
        this.pendingDetailShow = true;
        this.swapLoading = true;
        jackRouterContract.methods
          .swapExactTokensForTokens(amountIn, amountOutMin, path, to, deadline)
          .send({ from: this.account, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
          .on('transactionHash', hash => {
            const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap1')} ${this.toInput} ${this.toCur.symbol}`;
            const title = this.$t('lang.swap18');
            const transactionsDetail = {
              hash,
              title,
              message,
              addedTime: new Date().getTime(),
              from: this.account,
            };
            this.$store.commit('SETTRANSACTIONS', transactionsDetail);
            this.pendingDetail.status = 1;
            this.pendingDetail.hash = hash;
          })
          .on('receipt', receipt => {
            this.swapLoading = false;
          })
          .on('error', (error, receipt) => {
            this.pendingDetail.status = 2;
            this.swapLoading = false;
            throw error;
          });
      }
    },
    async swapTokensForExactTokens(amountOut, amountInMax, path, to, deadline) {
      const jackRouterContract = useJackRouterContract();
      const gas = await jackRouterContract.methods.swapTokensForExactTokens(amountOut, amountInMax, path, to, deadline).estimateGas({ from: this.account });
      this.swapDetailShow = false;
      this.pendingDetailShow = true;
      this.swapLoading = true;
      jackRouterContract.methods
        .swapTokensForExactTokens(amountOut, amountInMax, path, to, deadline)
        .send({ from: this.account, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
        .on('transactionHash', hash => {
          const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap1')} ${this.toInput} ${this.toCur.symbol}`;
          const title = this.$t('lang.swap18');
          const transactionsDetail = {
            hash,
            title,
            message,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
          this.pendingDetail.status = 1;
          this.pendingDetail.hash = hash;
        })
        .on('receipt', receipt => {
          this.swapLoading = false;
        })
        .on('error', error => {
          this.pendingDetail.status = 2;
          this.swapLoading = false;
          throw error;
        });
    },
    //获取所有的交易对
    async handleGetAllPair() {
      if (this.getWrap || this.getWithdraw) {
        this.allpaths = [];
        return;
      }
      if (this.fromCur.address == '' || this.toCur.address == '') {
        return;
      }
      const allPair = getAllPair(this.fromCur, this.toCur);
      console.log('allPair',allPair);
      const promisePairAddress = allPair.map(item => {
        const jackFactoryContract = getJackFactoryContract();
        console.log('jackFactoryContractV2',jackFactoryContract);
        const pairAddress = jackFactoryContract.methods.getPair(item.fromCur.address, item.toCur.address).call();
        console.log('pairAddress',pairAddress);
        return pairAddress;
      });
      let allPairAddress = await Promise.all(promisePairAddress);
      console.log('allPairAddress',allPairAddress);
      let lpPair = [];
      for (let i = 0; i < allPair.length; i++) {
        const lpAddress = allPairAddress[i];
        if (lpAddress == '0x0000000000000000000000000000000000000000') {
          continue;
        }
        lpPair.push({ lpAddress, ...allPair[i] });
      }
      let promiseReserve = lpPair.map(item => {
        const jackPairContract = getJackPairContract(item.lpAddress);
        const reserves = jackPairContract.methods.getReserves().call();
        return reserves;
      });

      const allReserves = await Promise.all(promiseReserve);
      let newlpPair = lpPair.map((item, index) => {
        let newItem = JSON.parse(JSON.stringify(item));
        if (newItem.fromCur.address.toLowerCase() < newItem.toCur.address.toLowerCase()) {
          newItem.fromCur.reserves = allReserves[index]._reserve0;
          newItem.toCur.reserves = allReserves[index]._reserve1;
        } else {
          newItem.fromCur.reserves = allReserves[index]._reserve1;
          newItem.toCur.reserves = allReserves[index]._reserve0;
        }
        return newItem;
      });

      let pathFrom = [];
      for (let i of newlpPair) {
        if (i.fromCur.address == this.fromCur.address) {
          pathFrom.push(i);
        }
      }
      let pathTo = [];

      for (let i of newlpPair) {
        if (i.toCur.address == this.toCur.address) {
          pathTo.push(i);
        }
      }

      let allpaths = [];
      // 算出到底有多少条路径
      for (let i = 0; i < pathFrom.length; i++) {
        const item = pathFrom[i];
        const findItem = pathTo.find(toItem => {
          return item.toCur.address == toItem.fromCur.address;
        });
        if (findItem && findItem.toCur.address == this.toCur.address) {
          allpaths.push([item, findItem]);
          continue;
        }
        if (item.toCur.address == this.toCur.address) {
          allpaths.push([item]);
        }
      }
      this.allpaths = allpaths;
      if (this.inputSource === 'from') {
        this.getAmountsOut();
      }

      if (this.inputSource === 'to') {
        this.getAmountsIn();
      }
    },

    // from的输入框
    async hanldeInputFrom() {
      this.inputSource = 'from';
      this.getAmountsOut();
    },

    //to的输入框
    hanldeInputTo() {
      this.inputSource = 'to';
      this.getAmountsIn();
    },

    // 是否需要显示授权按钮
    async handleApproveBtnShow(isSecondary) {
      console.log(this.fromCur.address);
      console.log(this.fromCur);
      console.log(11111111111111111111111111111111);
      if (isBnb(this.fromCur) || this.fromCur.address == '') {
        this.allowanceToRouter = false;
        return;
      }
      if (this.account) {
        console.log(2222222222222222222222);
        const erc20Contract = getErc20Contract(this.fromCur.address);
        console.log(erc20Contract);
        const to = getJackRouterAddress();
        console.log(to);
        let allowance = await erc20Contract.methods.allowance(this.account, to).call();
        allowance = parseFloat(allowance);
        console.log('allowance', allowance);
        const erc20ContractUSDT = getErc20Contract('0x55d398326f99059ff775485246999027b3197955');
        console.log('erc20ContractUSDT', erc20ContractUSDT);
        const allowanceToAddress = 0;
        // const toAddress = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
        // const allowanceToAddress = await erc20ContractUSDT.methods.allowance(this.account, toAddress).call();
        console.log('allowanceToAddress', allowanceToAddress);
        if (allowance == 0) {
          this.allowanceToRouter = true;
        } else {
          this.allowanceToRouter = false;
        }
        // if (this.fromCur.address == '0x55d398326f99059ff775485246999027b3197955') {
        //   if (allowance == 0) {

        //     this.allowanceToRouter = true;
        //   } else if (allowance != 0 && allowanceToAddress == 0 && !isSecondary && this.fromCur.address == '0x55d398326f99059ff775485246999027b3197955') {
        //     console.log(44444444444444444444444444);
        //     this.SecondaryAuthorization = true;
        //     this.allowanceToRouter = true;
        //   } else {
        //     this.allowanceToRouter = false;
        //   }
        // } else if (allowance == 0) {
        //   console.log('allowanceToRouter=true');
        //   this.allowanceToRouter = true;
        // } else {
        //   console.log('allowanceToRouter=false');
        //   this.allowanceToRouter = false;
        // }
      }
    },
    // 设置输入框最大值
    async handleToMax(multiple) {
      if (isBnb(this.fromCur)) {
        // if (this.fromInput > 0.01) {
        this.fromInput = significantDigits(this.fromCur.balance * 1 * multiple);
        // } else {
        //   this.fromInput = 0;
        // }
      } else {
        this.fromInput = significantDigits(this.fromCur.balance * multiple);
      }

      this.inputSource = 'from';
      this.getAmountsOut();
    },
    async initialization() {
      this.handleCurBalance();
      // 余额定时器请求
      this.handleTimerBalance();
      if (this.site) {
        this.bind();
      }
    },
    async handleTimerBalance() {
      window.clearTimeout(this.timerBalance);
      this.timerBalance = setInterval(async () => {
        this.handleCurBalance();
      }, 3000);
    },
    // 修改价格方向
    changePriceDirection() {
      this.priceDirection = !this.priceDirection;
      this.getPairPrice();
    },

    //是否显示设置得余额
    handleSettingShow() {
      this.settingShow = true;
    },
    handleRecordShow() {
      this.transactionRecordShow = true;
    },
    async handlerSelecteCurrency(currency) {
      this.tokenListShow = false;
      if (this.tokenSource == 'from') {
        if (currency.address == this.toCur.address && currency.name == this.toCur.name) {
          this.turnAround();
        } else {
          this.fromCur = currency;
        }
      } else {
        if (currency.address == this.fromCur.address && currency.name == this.fromCur.name) {
          this.turnAround();
        } else {
          this.toCur = currency;
        }
      }
      this.handleGetAllPair();
      this.handleApproveBtnShow();
      this.handleCurBalance();
    },

    // 流动池价格更换
    async getPairPrice() {
      if (parseFloat(this.fromInput) === 0 || parseFloat(this.toInput) === 0) {
        this.fromInput = '';
        return;
      }

      if (this.fromCur.address === '' || this.toCur.address === '') {
        this.fromInput = '';
        return;
      }
      const fromInput = parseFloat(this.fromInput);
      const toInput = parseFloat(this.toInput);
      if (this.priceDirection) {
        const price = accDiv(fromInput, toInput);
        this.price = significantDigits(price);
      } else {
        const price = accDiv(toInput, fromInput);
        this.price = significantDigits(price);
      }
      this.priceShow = true;
    },

    //获取实时交易显示
    async getAmountsOut() {
      this.insufficientLiquidityShow = false;
      if (parseFloat(this.fromInput) === 0 || this.fromInput == '') {
        this.toInput = '';
        this.priceShow = false;
        return;
      }

      if (this.fromCur.address === '' || this.toCur.address === '') {
        this.toInput = '';
        this.priceShow = false;
        return;
      }

      if (this.getWrap || this.getWithdraw) {
        this.toInput = this.fromInput;
        return;
      }

      if (this.multipath) {
        console.log(this.allpaths,'this.allpaths');
        this.currentPaths = this.allpaths.filter(item => {
          return item.length == 1;
        });
      } else {
        this.currentPaths = this.allpaths;
      }
      let pathIndex = -1;
      let max = 0;
      for (let i = 0; i < this.currentPaths.length; i++) {
        const pathItem = this.currentPaths[i];
        let amounts = [];
        amounts[0] = parseAmount(this.fromInput, this.fromCur.decimals);
        for (let j = 0; j < pathItem.length; j++) {
          let reserveIn = pathItem[j].fromCur.reserves;
          let reserveOut = pathItem[j].toCur.reserves;
          if (reserveIn == 0 || reserveOut == 0) {
            amounts[j + 1] = 0;
            break;
          }
          let amountInWithFee = accMul(amounts[j], 997);
          let numerator = accMul(amountInWithFee, reserveOut);
          let denominator = accAdd(accMul(reserveIn, 1000), amountInWithFee);
          let amountOut = toFixed(accDiv(numerator, denominator), 0);
          amounts[j + 1] = amountOut;
        }
        if (accGt(amounts[amounts.length - 1], max)) {
          max = amounts[amounts.length - 1];
          pathIndex = i;
        }
      }
      if (pathIndex == -1 || this.currentPaths.length == 0) {
        this.insufficientLiquidityShow = true;
        this.toInput = '';
        return;
      }
      this.pathIndex = pathIndex;
      let list = this.currentPaths[this.pathIndex];
      this.pathRouter = '';
      this.proportion(list);
      for (let k = 0; k < list.length; k++) {
        if (k == 0) {
          this.pathRouter = this.pathRouter + list[k].fromCur.symbol + '>' + list[k].toCur.symbol;
        } else {
          this.pathRouter = this.pathRouter + '>' + list[k].toCur.symbol;
        }
      }
      this.toInput = formatAmount(max, this.toCur.decimals);
      this.getPairPrice();
    },

    proportion(list) {
      let shuru = this.fromInput * 1 * Math.pow(10, this.fromCur.decimals);
      let one = accDiv(shuru, accAdd(shuru, list[0].fromCur.reserves)) * 100; // 第一个的影响
      this.portion = one;
      if (list.length > 1) {
        let two =
          accDiv(
            accMul(shuru, list[0].toCur.reserves),
            accAdd(accAdd(accMul(shuru, list[0].toCur.reserves), accMul(shuru, list[1].fromCur.reserves)), accMul(list[0].fromCur.reserves, list[1].fromCur.reserves))
          ) * 100;
        this.portion = this.portion + two;
      }
      if (this.portion >= 99.75) {
        this.portion = 99.75;
      }
    },

    async getAmountsIn() {
      this.insufficientLiquidityShow = false;
      if (parseFloat(this.toInput) === 0 || this.toInput == '') {
        this.fromInput = '';
        this.priceShow = false;
        return;
      }

      if (this.fromCur.address === '' || this.toCur.address === '') {
        this.fromInput = '';
        this.priceShow = false;
        return;
      }

      if (this.getWrap || this.getWithdraw) {
        this.fromInput = this.toInput;
        return;
      }

      if (this.multipath) {
        this.currentPaths = this.allpaths.filter(item => {
          return item.length == 1;
        });
      } else {
        this.currentPaths = this.allpaths;
      }
      let pathIndex = -1;
      let min = 0;

      for (let i = 0; i < 1; i++) {
        const pathItem = this.currentPaths[i];
        let amounts = [];

        amounts[pathItem.length] = parseAmount(this.toInput, this.toCur.decimals);
        for (let j = pathItem.length; j > 0; j--) {
          let reserveIn = pathItem[j - 1].fromCur.reserves;
          let reserveOut = pathItem[j - 1].toCur.reserves;
          if (reserveIn == 0 || reserveOut == 0) {
            amounts[j - 1] = 0;
            break;
          }
          let numerator = accMul(accMul(reserveIn, amounts[j]), 1000);
          let denominator = accMul(accSub(reserveOut, amounts[j]), 997);
          let amountIn = toFixed(accAdd(accDiv(numerator, denominator), 1), 0);
          amounts[j - 1] = amountIn;
        }

        if (accGt(amounts[0], 0)) {
          if (i == 0) {
            min = amounts[0];
            pathIndex = i;
          } else if (accGt(min, amounts[0])) {
            min = amounts[0];
            pathIndex = i;
          }
        }
      }

      this.pathIndex = pathIndex;
      // 流动性不足
      if (pathIndex == -1 || this.currentPaths.length == 0) {
        this.insufficientLiquidityShow = true;
        this.fromInput = '';
        return;
      }
      this.fromInput = formatAmount(min, this.fromCur.decimals);
      this.getPairPrice();
    },
    handleGetCurrency(tokenSource) {
      this.tokenSource = tokenSource;
      this.tokenListShow = true;
    },

    // 点击反转按钮
    handleTurnAround() {
      this.turnAround();
      this.handleGetAllPair();
      this.handleApproveBtnShow();
      this.handleCurBalance();
    },

    // 交换from to数据
    turnAround() {
      const tempCurrency = { ...this.toCur };
      this.toCur = { ...this.fromCur };
      this.fromCur = { ...tempCurrency };

      const tempInput = this.toInput;
      this.toInput = this.fromInput;
      this.fromInput = tempInput;

      this.inputSource = this.inputSource === 'from' ? 'to' : 'from';
    },

    async handleApprove() {
      const erc20Contract = useErc20Contract(this.fromCur.address);
      const amount = MaxUint256.toString();
      let to = '';
      // if (this.SecondaryAuthorization) {
      //   to = '0x0D0f95CA419FB129dcA1ED87FEC99E0959F9f779';
      // } else {
      to = getJackRouterAddress();
      // }
      const gas = await erc20Contract.methods.approve(to, amount).estimateGas({ from: this.account });
      this.approveLoading = true;
      erc20Contract.methods
        .approve(to, amount)
        .send({ from: this.account, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
        .on('transactionHash', hash => {
          const transactionsDetail = {
            title: '',
            hash,
            message: `${this.$t('lang.swap14')} ${this.fromCur.symbol}`,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          if (to == '0x0D0f95CA419FB129dcA1ED87FEC99E0959F9f779') {
            let data = {
              address: this.account,
            };
            invite.activityJoin(data).then(res => {});
          }
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
        })
        .on('receipt', receipt => {
          this.handleApproveBtnShow(true);
          this.approveLoading = false;
        })
        .on('error', (error, receipt) => {
          this.approveLoading = false;
          this.handleApproveBtnShow();
          throw error;
        });
    },
    visualChartClose() {
      this.VisualChartShow = !this.VisualChartShow;
    },
    // 弹窗开关
    swapSwitch() {
      this.swapPopupShow = !this.swapPopupShow;
    },
    // 弹窗确认按钮
    popupSwitch() {
      this.swapPopupShow = false;
      this.handleSwap();
    },
    significantDigits,
    accSub,
    accMul,
  },
};
</script>
  
  <style lang="less" scoped>
/deep/ .van-popup--center {
  border-radius: 30px !important;
}

.swap {
  background: var(--box-bg-color);
  width: 100%;

  border-radius: 30px 30px 0 0;
  // box-shadow: 0px 6px 32px rgba(13, 13, 13, 0.08);
  // border: 2px solid #f2f2f2;
  // border: 1px solid #707070;

  .headWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;

    .headWrap_left {
      font-size: 20px;
      color: var(--text-color);
      font-weight: bold;

      .point {
        font-size: 12px;
        font-weight: normal;
        color: #848a96;
        margin-top: 4px;
      }
    }

    .headWrap_img {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      border-radius: 14px;

      div {
        // background: rgba(199, 199, 199, 0.2);
        // border-radius: 50%;
        // padding: 2px;
        margin-left: 10px;
      }

      .iconSet {
        display: block;
        width: 40px;
        height: 40px;
        // margin-right: 23px;
        cursor: pointer;
      }

      .iconRecord {
        display: block;
        width: 40px;
        height: 40px;
        cursor: pointer;
      }

      .iconHash {
        display: block;
        width: 40px;
        height: 40px;
      }
    }
  }

  .container {
    padding: 20px;

    .box {
      padding: 10px 0;
      // background: @background-gray;

      // border: 1px solid #3399ff;
      .balances {
        font-size: 14px;
        font-weight: 500;
        max-width: 60%;
        color: var(--text-color);
        opacity: 0.6;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
      }

      .btRow {
        padding: 9px;
        width: 100%;
        display: flex;
        align-items: center;
        background: var(--line-bg-color);
        border-radius: 20px;
        margin-top: 20px;
        .fromCurBox {
          padding: 13px;
          min-width: 132px;
          height: 58px;
          opacity: 1;
          border-radius: 10px;
          background: var(--bg-color);
          display: flex;
          align-items: center;
          justify-content: space-between;
          .tokenImg {
            width: 32px;
            height: 32px;
            margin-right: 10px;
          }
          .fromCurBox_right {
            .fromCurBox_right_top {
                width: 100%;
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: 700;
              color: var(--text-color);
              .arrows_img {
                margin-left: 10px;
                width: 10px;
                height: 6px;
              }
            }
            .fromCurBox_right_bottom {
              font-size: 10px;
              font-weight: 500;
              color: rgba(164, 165, 171, 1);
            }
          }
        }
      }

      .van-field {
        flex: 1;
        border-radius: 87px;
        font-weight: bold;
        background: none !important;
        padding: 16px 20px !important;

        /deep/ .van-field__control {
          font-size: 18px;
          color: var(--text-color);
        }
      }

      .van-cell {
        background: transparent;
        padding: 0;
      }

      .tokenSymbol {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: var(--text-color);

        span {
          font-size: 14px;
          // border: 1px solid #3399ff;
          border-radius: 8px;
          padding: 2px 4px;
          text-align: center;
          cursor: pointer;
        }

        .arrows_img {
          display: block;
          width: 12px;
          height: 6px;
          margin-left: 6px;
          margin-top: 4px;
        }
      }

      .maxWord {
        color: var(--text-color);
        background: #3399ff;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        border-radius: 9px;
        white-space: nowrap;
        padding: 4px 6px;
        margin-right: 10px;
      }

      .selectedCurrency {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .tokenDetail {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: var(--text-color);
          font-weight: bold;
          // border: 1px solid #3399ff;
          border-radius: 8px;
          // padding: 2px 2px;
          margin: 0 2px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行

          div {
            // width: 100%;
            // max-width: 56px;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }

          .arrows_img {
            display: block;
            width: 12px;
            height: 6px;
            margin-left: 6px;
            margin-top: 4px;
          }
        }

        .tokenImg {
          margin-right: 6px;
          width: 24px !important;
          height: 24px !important;
        }
      }
    }

    .percentage {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px 20px;

      .percentage_li {
        display: block;
        font-size: 12px;
        color: var(--text-color);
        border-radius: 8px;
        background: var(--box-bg-color);
        padding: 4px 10px;
      }
    }

    .iconDown {
      height: 18px;
      width: 18px;
      display: block;
      margin: 13px auto;
      cursor: pointer;
      // transform: rotate(90deg);
    }

    .price {
      padding: 0 10px;
      margin-top: 15px;

      .text {
        color: var(--text-color);
        opacity: 0.6;
        font-size: 14px;
        font-weight: bold;
      }

      .opppsite {
        height: 18px;
        width: 18px;
      }
    }

    .slipWrap {
      padding: 0 10px;
      margin-top: 15px;
      color: #000;

      .text {
        font-size: 14px;
        font-weight: bold;
        color: var(--text-color);
        opacity: 0.6;
      }
    }

    .swapBtn {
      border: none;
      width: 100%;
      height: 52px;
      margin-top: 22px;
      font-size: 16px;
      color: rgba(0, 0, 0, 1) !important;
      font-weight: bold;
      border-radius: 84px;
      background: rgba(152, 232, 110, 1);
      padding: 20px 0 !important;
    }
  }
}

.current_prompt {
  background: var(--box-bg-color);
  border-radius: 14px;
  margin: 20px;
  border: 1px solid rgba(45, 58, 82, 1);
  padding: 18px 14px;

  margin-bottom: 50px;

  .tips {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 100;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 18px;

    color: rgba(0, 0, 0, 1);
    margin-bottom: 30px;

    .subtitle {
      color: var(--text-color);
      opacity: 0.4;
    }

    .special_color {
      color: var(--text-color);
    }

    .special {
      text-align: right;
    }
  }
}
</style>
  